







































































import { Component, Vue } from 'vue-property-decorator';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';
import {
    getBipTitle,
    getCommonTitle,
    getSmsTitle
} from "@/modules/budget/bip/bip-types";
import * as CryptoJS from "crypto-js";
import NCAlayerService from "@/services/ncalayer";
import store from "@/services/store";

@Component({
    name: 'c-sign-log-modal'
})
export default class CBudgetSignLogModal extends Vue {

    private sign: any;
    private params: any;

    private showModal = false;

    private comment = '';
    private signer = [];
    private logLst: any[] = [];

    get userUiid() {
        return store.state.user.sub;
    }

    get userIin(): string | null {
        // return 'abab80c6-525b-4151-9499-a1ff21a157d2'; // ----- временнно!!!!!!!!
        if (!store.state.user.preferred_username) { return null; }
        return store.state.user.preferred_username;
    }

    private showEvent(sign: any, params: any) {
        this.sign = sign;
        this.params = params;
        this.logLst = [];
        // const log = this.sign.signers.filter((row:any) => row.cost && row.cost !== null);
        const log = this.sign['signLog'];
        log.forEach((cost: any) => {
            const signer  = this.sign.signers.filter((row:any) => row.id_user === cost.user_id);
            this.$set(cost, 'signer', signer.length > 0 ? signer[0] : undefined)
            if (cost.deleted) {
                const item = {
                    'deleted': false,
                    'name': (cost.signer ? cost.signer.name : 'Подписант удален'),
                    'sign_date': cost.sign_date,
                    'comment': ''
                };
                const item1 = {
                    'deleted': cost.deleted,
                    'name': (cost.signer ? cost.signer.name : 'Подписант удален'),
                    'sign_date': cost.del_date,
                    'comment': (cost.comment === null ? '' : cost.comment)
                };
                this.logLst.push(item);
                this.logLst.push(item1);
            } else {
                this.logLst.push({
                    'deleted': cost.deleted,
                    'name': (cost.signer ? cost.signer.name : 'Подписант удален'),
                    'sign_date': cost.sign_date,
                    'comment': ''
                });
            }
        })

        this.signer = this.sign.signers.filter((row: any) => row.id_user === this.userUiid);
        this.showModal = true;
    }

    private dateFormat(date: Date): string {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }

    private async startSign() {
        if (!this.userIin || this.userIin.length < 3) {
            makeToast(this, 'warning', 'Подписание', 'Не удалось получить ИИН пользователя!');
            console.error('Не удалось получить ИИН пользователя!', this.userIin, this.userUiid)
            return;
        }

        const data = {
            variant: this.params.version.variant_uuid,
            abp: this.params.abp.abp,
            cur_year: this.params.version.year,
            data_type: this.params.version.data_type,
            region: this.params.version.region_code,
            val1: this.params.val1,
            val2: this.params.val2,
            val3: this.params.val3
        };

        const hash: string = await CryptoJS.SHA256(JSON.stringify(data)).toString();
        let response = null;
        try {
            response = await NCAlayerService.signData(hash,
                { iin: (this.userIin !== null ? this.userIin.slice(3) : '') });
            if (response.error) {
                makeToast(this, 'danger', getSmsTitle(this, 'error_sign'), response.error);
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_sign'), (error as Error).toString());
            return;
        }

        if (!response.result) { return; }

        const params = Object.assign({
            hash: hash,
            sign: response.result,
            user_id: this.userUiid}, data);
        let result: any = await fetch('/api-py/sign-cost-data',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(params)
            });
        if (result.status === 200) {
            result = await result.json();

            if (result.result === 'success') {
                makeToast(this, 'success', 'Подписание', 'Данные подписаны');
                this.showModal = false;
                this.$emit("updateSign", true);
            } else {
                makeToast(this, 'danger', 'Подписание', result.txt);
            }
        } else {
            makeToast(this, 'danger', 'Подписание', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }

    private async revokeSign() {

        const params = {
            variant: this.params.version.variant_uuid,
            abp: this.params.abp.abp,
            comment: this.comment,
            user_id: this.userUiid,
            auto: false
        };

        let result: any = await fetch('/api-py/revoke-cost-data',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(params)
            });
        if (result.status === 200) {
            result = await result.json();

            if (result.result === 'success') {
                makeToast(this, 'success', 'Отзыв подписи', 'Подпись отозвана');
                this.showModal = false;
                this.$emit("updateSign", true);
            } else {
                makeToast(this, 'danger', 'Отзыв подписи', result.txt);
            }
        } else {
            makeToast(this, 'danger', 'Подписание', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
        this.comment = '';
        this.showModal = false;
    }

    private getBipTitle(field: string) {
        return getBipTitle(this, field);
    }

    private getCdfTitle(field: string) {
        return getCommonTitle(this, 'modules.costdataform.' + field)
    }
}
