



























/*interface ISignUser {
    create_date: null | number,
    name: string,
    userName: string,
    user_id: string
}*/

import { Component, Vue } from 'vue-property-decorator';
import { getCommonTitle } from "@/modules/budget/bip/bip-types";

@Component({
    name: 'c-sign-status-modal'
})
export default class CBudgetSignStatusModal extends Vue {
    private showModal = false;
    private signUserLst: any[] = [];

    private  showEvent(signUserLst: any[]) {
        this.signUserLst = [];
        signUserLst.forEach((row: any) => {
            if (!row.cost || (row.cost === null)) {
                const item = {
                    deleted: true,
                    name: row.name,
                    sign_name: row.sign_name,
                    date: ''
                }
                this.signUserLst.push(item);
            }
            if (row.cost && (row.cost.length > 0)) {
                const sign = row.cost.filter((row: any) => !row.deleted);
                if (sign.length > 0) {
                    const item = {
                        deleted: sign[0].deleted,
                        name: row.name,
                        sign_name: row.sign_name,
                        date: sign[0].sign_date
                    }
                    this.signUserLst.push(item);
                } else {
                    const item = {
                        deleted: true,
                        name: row.name,
                        sign_name: row.sign_name,
                        date: ''
                    }
                    this.signUserLst.push(item);
                }
            }
        });
        this.showModal = true;
    }

    private getCdfTitle(field: string) {
        return getCommonTitle(this, 'modules.costdataform.' + field)
    }
}
