<template>
    <div class="inner-container budget-form">

        <!--     фильтры/кнопки     -->
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i>{{ getBipTitle('filter.title') }}</span>
                        <span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>{{ getFilterTitle('title') }}</span>
                            <i class="icon icon-close"
                                @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('plan_period')">
                                <multiselect ref="bcPlanYear"
                                             v-model="planPeriod"
                                             track-by="name"
                                             label="name"
                                             :options="yearsFilter"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Область/район бюджета ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('region_budget')">
                                <multiselect ref="bcRegionBudget"
                                             v-model="selRegionBudget"
                                             track-by="name"
                                             label="name"
                                             :options="regionBudgetList"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Вид данных ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('data_type')">
                                <multiselect ref="bcDataType"
                                             v-model="selDataType"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="dataTypeList"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Версия бюджета ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('version')">
                                <multiselect ref="bcVersion"
                                             v-model="selVersion"
                                             track-by="text"
                                             label="text"
                                             :placeholder="getFilterTitle('all')"
                                             :options="versionFilter"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Месторасположение проекта ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('location')">
                                <multiselect ref="bcRegion"
                                             v-model="selRegion"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="regionFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="changeRegion">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Направление ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('direction')">
                                <multiselect ref="bcDirection"
                                             v-model="selDirection"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="directionFilter"
                                             :searchable="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Тип объекта ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('object_type')">
                                <multiselect ref="bcObject"
                                             v-model="selObject"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="objectFilter"
                                             :searchable="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Статус ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('status')">
                                <multiselect ref="bcStatus"
                                             v-model="selStatus"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="statusFilter"
                                             :searchable="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- АБП ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('ABP')">
                                <multiselect ref="bcAbp"
                                             v-model="selAbp"
                                             track-by="text"
                                             label="text"
                                             :placeholder="getFilterTitle('all')"
                                             :options="abpFilter"
                                             :searchable="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Гос.программа ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('gov_program')">
                                <multiselect ref="bcGp"
                                             v-model="selGp"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="gpFilter"
                                             :searchable="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Характер расходов ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('expenses_type')">
                                <multiselect ref="bcRadio"
                                             v-model="radio"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="expTypes"
                                             :searchable="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
            </div>
        </div>

        <!--     Список проектов     -->
        <div v-show="!showForm">
            <!--        хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod"
                      @click="openFilterByRef('bcPlanYear')">
                    {{ planPeriod.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selRegionBudget"
                      @click="openFilterByRef('bcRegionBudget')">
                    {{ selRegionBudget.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selDataType"
                      @click="openFilterByRef('bcDataType')">
                    {{ selDataType.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selVersion"
                      @click="openFilterByRef('bcVersion')">
                    {{ selVersion.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selRegion"
                      @click="openFilterByRef('bcRegion')">
                    {{ selRegion.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selDirection"
                      @click="openFilterByRef('bcDirection')">
                    {{ selDirection.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selObject"
                      @click="openFilterByRef('bcObject')">
                    {{ selObject.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selStatus"
                      @click="openFilterByRef('bcStatus')">
                    {{ selStatus.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selAbp"
                      @click="openFilterByRef('bcAbp')">
                    {{ selAbp.abp + '-' + getFilterTitle('ABP') }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selGp"
                      @click="openFilterByRef('bcGp')">
                    {{ selGp.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && radio"
                      @click="openFilterByRef('bcRadio')">
                    {{ radio.name }}
                </span>
            </div>
            <b-progress variant="success" v-show="pBar < 100" height="10px" :value="pBar" striped animated></b-progress>
            <div class="table-container">
                <b-table :fields="tableFields"
                         :items="showBipList"
                         :striped="true"
                         responsive="true"
                         bordered
                         head-variant="light"
                         sticky-header="true"
                         no-border-collapse>

                    <template #thead-top="data">
                        <b-tr>
                            <b-th colspan="8"></b-th>
                            <b-th colspan="4" class="text-center">
                                {{ getTFieldTitle('grade') + ', ' + getTFieldTitle('point') }}
                            </b-th>
                            <b-th colspan="2"></b-th>
                        </b-tr>
                    </template>
                    <template #head(action)="scope">
                        <b-button @click="openAll()">
                            <i v-if="open" class="icon icon-chevron-circle icon-rotate-180"></i>
                            <i v-if="!open" class="icon icon-chevron-circle"></i>
                        </b-button>
                    </template>

                    <template #cell(action)="data">
                        <b-button @click="showDetails(data.item)">
                            <i class="icon icon-chevron-circle icon-rotate-180"
                               v-if="data.item._showDetails">
                            </i>
                            <i class="icon icon-chevron-circle"
                               v-if="!data.item._showDetails">
                            </i>
                        </b-button>
                    </template>
                    <template #cell(reg_loc)="data">
                        <div class="text-left">
                            {{ data.item.addDatas.reg_loc }}
                        </div>
                    </template>
                    <template #cell(abp)="data">
                        <div>{{ data.item.addDatas.abp.text }}</div>
                    </template>
                    <template #cell(exp_type)="data">
                        <div>{{ data.item.addDatas.exp_type }}</div>
                    </template>
                    <template #cell(program)="data">
                        <div>{{ data.item.addDatas.program }}</div>
                    </template>
                    <template #cell(type_obj)="data">
                        <div>{{ data.item.addDatas.type_obj }}</div>
                    </template>
                    <template #cell(name)="data">
                        <div class="text-left">
                            <a href="#" @click="openBip(data.item)">{{ data.value }}</a>
                        </div>
                    </template>
                    <template #cell()="data">
                        <div class="text-left">{{ data.value }}</div>
                    </template>
                    <template #cell(status)="data">
                        <div v-b-modal.modal-test
                             class="text-bold"
                             :class="{green: data.item.addDatas.status && [47, 51].includes(data.item.addDatas.status.code),
                             red: data.item.addDatas.status && [48, 52].includes(data.item.addDatas.status.code)}">
                            {{ data.item.addDatas.status ? data.item.addDatas.status.name : findItem(1, 'code', statusList).name }}
                        </div>
                    </template>
                    <template #cell(total)="data">
                        <div
                            class="text-bold text-center"
                            style="font-size: 1.1rem"
                            :class="{
                                'text-danger': parseFloat(data.item.total) < 4,
                                'text-warning':
                                    parseFloat(data.item.total) >= 4 &&
                                    parseFloat(data.item.total) < 7,
                                'text-success':
                                    parseFloat(data.item.total) >= 7,
                            }"
                        >
                            {{ data.item.total }}
                        </div>
                    </template>
                    <template #cell(more)>
                        <b-button class="more">
                            <i class="icon icon-more"></i>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <b-table :fields="row.item.details.fields"
                                     :items="row.item.details.table"
                                     responsive="true"
                                     bordered
                                     head-variant="light"
                                     sticky-header="true"
                                     no-border-collapse>
                                <template #thead-top="data">
                                    <b-tr>
                                        <b-th></b-th>
                                        <b-th v-if="row.item.details['mapBudget'].size > 0"
                                              :colspan="row.item.details['mapBudget'].size"
                                              class="text-center">
                                            {{ getTFieldTitle('budget_criteria' )}}
                                        </b-th>
                                        <b-th v-if="row.item.details['mapImpact'].size > 0"
                                              :colspan="row.item.details['mapImpact'].size"
                                              class="text-center">
                                            {{ getTFieldTitle('influence') }}
                                        </b-th>
                                        <b-th v-if=" row.item.details['mapNeeds'].size > 0"
                                              :colspan="row.item.details['mapNeeds'].size"
                                              class="text-center">
                                            {{ getTFieldTitle('project_need') }}
                                        </b-th>
                                        <b-th></b-th>
                                    </b-tr>
                                </template>
                                <template #cell(name)="data">
                                    <div class="text-left">
                                        {{ data.value }}
                                    </div>
                                </template>
                                <template #cell()="data">
                                    <div class="text-right">
                                        {{ data.value }}
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </template>
                </b-table>
            </div>
        </div>

        <!--     Форма БИПа     -->
        <div v-show="showForm" v-if="bipProjectData">
            <!--     кнопки     -->
            <div class="filter-container">
                <div class="left-content"></div>
                <div class="right-content">
                    <div class="filter-actions">
                        <b-button class="ml-auto"
                                  variant="light"
                                  @click="showForm = !showForm">
                            {{ getBipTitle('btn.back_list') }}
                        </b-button>
                    </div>
                </div>
            </div>
            <bip-card :showForm="showForm"
                      :variant="selVersion"
                      :bip="bipProjectData"
                      :location="location"

                      :abp-base="abpBase"
                      :reg-abp-base="regAbpBase"
                      :spf-list="spfList"

                      :region-list="regionList"
                      :version-list="versionList"
                      :dataTypeList="dataTypeList"
                      :direction-list="directionList"
                      :gp-list="gpList"
                      :exp-types="expTypes"
                      :bip-cif-list="bipCifList"
                      :criteria-values="criteriaValues"
                      :bip-link-criterias="bipLinkCriterias"
                      :bip-link-types="bipLinkTypes"
                      :dict-unit="dictUnit">
            </bip-card>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "../../../services/store";
import i18nService from "@/services/i18n";
import VueElementLoading from "vue-element-loading";
import BipCard from "@/modules/budget/bip/bip-card.vue";
import {
    BipProjectDataClass,
    makeToast,
    years,
    sortByField,
    padLeadingZeros,
    filterVersion,
    defineBip,
    findItem, versionByRegion, setCurVariant,
    nameLocale,
    getBipTitle, getCardTitle, getFilterTitle, getTFieldTitle, getSmsTitle, loadEbkMap
} from "./bip-types";

export default {
    name: "bip-grade",
    components: { BipCard, loading: VueElementLoading },
    props: {
        location: undefined,
        regionBudgetList: {
            type: Array,
            default: () => []
        },
        versionList: {
            type: Array,
            default: () => []
        },
        detailFields: {
            type: Array,
            default: () => []
        },
        levels: {
            type: Array,
            default: () => []
        },
        regLevels: {
            type: Array,
            default: () => []
        },
        regionList: {
            type: Array,
            default: () => []
        },
        dataTypeList: {
            type: Array,
            default: () => []
        },
        directionList: {
            type: Array,
            default: () => []
        },
        objectList: {
            type: Array,
            default: () => []
        },
        statusList: {
            type: Array,
            default: () => []
        },
        gpList: {
            type: Array,
            default: () => []
        },
        expTypes: {
            type: Array,
            default: () => []
        },

        bipCifList: {
            type: Array,
            default: () => []
        },
        criteriaValues: {
            type: Map,
            default: () => new Map()
        },
        bipLinkCriterias: {
            type: Array,
            default: () => []
        },
        bipLinkTypes: {
            type: Array,
            default: () => []
        },
        dictUnit: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            mode_code: 'bip',

            ebkMap: new Map(),
            abpBase: [],
            regAbpBase: [],
            spfList: [],
            agrMap: new Map(),

            planPeriod: null,
            selRegionBudget: null,
            selRegion: null, // текущий регион
            selDataType: null,
            selVersion: null,
            selKato: null, // текущий НП
            selDirection: null,
            selObject: null, // текущий тип объекта
            selStatus: null, // текущий статус
            selAbp: null,
            selGp: null,

            radio: null,

            bipList: [],
            bipProjectData: null,

            open: false,
            showForm: false,
            openFilterWindow: false,
            loading: false,
            pBar: 0
        };
    },

    created() {
        console.log(new Date().toISOString(), 'grade created', this.$i18n.locale);

        this.$watch("regionBudgetList", (value) => {
            if (value.length > 0) {
                console.log(new Date().toISOString(), 'grade regionBudgetList = ', value.length);
                this.selRegionBudget = this.regionBudgetList[0];
                versionByRegion(this);
            }
        });

        this.$watch("planPeriod", (value) => {
            if (value) {
                console.log(new Date().toISOString(), 'grade planPeriod = ', value);
                setCurVariant(this);
            }
        });
        this.$watch("selRegionBudget", async (value) => {
            if (value) {
                console.log(new Date().toISOString(), 'grade selRegionBudget = ', value);
                setCurVariant(this);
            }
        });
        this.$watch("selDataType", (value) => {
            if (value) {
                console.log(new Date().toISOString(), 'grade selDataType = ', value);
                setCurVariant(this, true);
            }
        });
        this.$watch("selVersion", (value) => {
            this.selRegion = null;
            this.selDirection = null;
            this.selObject = null;
            this.selStatus = null;
            this.selAbp = null;
            this.selGp = null;
            this.bipList = [];

            if (value && !this.loading) {
                console.log(new Date().toISOString(), 'grade selVersion', this.selVersion);
                this.loading = true;

                this.loadBipPackage();
            }
        });
    },

    beforeUpdate() {
        for (const row of this.showBipList) {
            if (row._showDetails) {
                row._rowVariant = "info";
            }
        }
    },

    computed: {
        userUiid() {
            return store.state.user.sub;
        },

        yearsFilter(){
            return years();
        },

        regionFilter() {
            const regions = new Set();

            for (const row of this.showBipList) {
                regions.add(row.region);
            }
            return this.regionList.filter((row) => regions.has(row.code));
        },

        versionFilter() {
            return filterVersion(this);
        }, // список для фильтра по версиям

        directionFilter() {
            const projects = new Set();

            for (const row of this.showBipList) {
                projects.add(parseInt(row.projectType));
            }
            return this.directionList.filter((row) => projects.has(parseInt(row.code)));
        },

        objectFilter() {
            const objects = new Set();

            for (const row of this.showBipList) {
                objects.add(parseInt(row.object));
            }
            return this.objectList.filter((row) => objects.has(parseInt(row.code)));
        },

        statusFilter() {
            const statuses = new Set();

            for (const row of this.showBipList) {
                if (row.addDatas.status) {
                    statuses.add(row.addDatas.status.code);
                }
            }

            return this.statusList.filter((row) => statuses.has(row.code));
        },

        abpFilter() {
            const abps = new Set();

            for (const row of this.showBipList) {
                if (row.addDatas.abp) {
                    abps.add(parseInt(row.addDatas.abp.abp));
                }
            }

            return Array.from(this.ebkMap.values()).filter((row) => abps.has(parseInt(row.abp)));
        },

        gpFilter() {
            const gps = new Set();

            for (const row of this.showBipList) {
                for (const gp of row.governmentProgram) {
                    gps.add(gp.code);
                }
            }
            return this.gpList.filter((row) => gps.has(row.code));
        },

        showBipList() {
            let result = this.bipList;

            if (this.radio !== null) {
                switch (this.radio.value) {
                    case 0:
                        result = this.bipList.filter(
                            (row) =>
                                row.hasOwnProperty("header") &&
                                parseInt(row.header.year) ===
                                parseInt(this.planPeriod.year) &&
                                row.hasOwnProperty("newProject") &&
                                row.newProject === 0
                        );
                        break;
                    case 1:
                        result = this.bipList.filter(
                            (row) =>
                                row.hasOwnProperty("header") &&
                                parseInt(row.header.year) ===
                                parseInt(this.planPeriod.year) &&
                                (!row.hasOwnProperty("newProject") ||
                                    (row.hasOwnProperty("newProject") &&
                                        row.newProject === 1))
                        );
                        break;
                }
            }

            if (this.selVersion) {
                result = result.filter((row) =>
                        row.variant === this.selVersion.variant_uuid ||
                        row.variant_recipient === this.selVersion.variant_uuid);
            }

            if (this.selRegion) {
                result = result.filter(
                    (row) => row?.region === this.selRegion.code
                );
            }

            if (this.selKato) {
                result = result.filter(
                    (row) =>
                        (row?.localityObject !== undefined &&
                            row?.localityObject.code === this.selKato.code) ||
                        row?.locality === this.selKato.code
                );
            }

            if (this.selDirection) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.projectType) ===
                        parseInt(this.selDirection.code)
                );
            }

            if (this.selObject !== null) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.object) === parseInt(this.selObject.code)
                );
            }

            if (this.selStatus !== null) {
                result = result.filter((row) => row.addDatas.status && row.addDatas.status.code === this.selStatus.code);
            }

            if (this.selAbp) {
                result = result.filter(
                    (row) =>
                        parseInt(row.addDatas.abp.abp) ===
                        parseInt(this.selAbp.abp)
                );
            }

            const gps = [];
            if (this.selGp) {
                for (const bip of result) {
                    for (const gp of bip?.governmentProgram) {
                        if (this.selGp.code === gp.code) {
                            gps.push(bip.id);
                        }
                    }
                }
                result = result.filter((row) => gps.includes(row.id));
            }

            return result;
        },

        tableFields() {
           return [
               {
                   key: "action",
                   label: " ",
                   class: "toggle-show",
               },
               {
                   key: "code",
                   label: this.getTFieldTitle('project_code'),
                   sortable: true,
               },
               {
                   key: "reg_loc",
                   label: this.getTFieldTitle('region') + '/' + this.getTFieldTitle('location'),
               },
               {
                   key: "abp",
                   label: this.getFilterTitle('ABP'),
               },
               {
                   key: "exp_type",
                   label: this.getFilterTitle('expenses_type'),
                   sortable: true,
               },
               {
                   key: "program",
                   label: this.getFilterTitle('gov_program'),
               },
               {
                   key: "type_obj",
                   label: this.getFilterTitle('direction') + '/' + this.getFilterTitle('object_type'),
               },
               {
                   key: "name",
                   label: this.getTFieldTitle('project_name'),
                   sortable: true,
               },
               {
                   key: "budget",
                   label: this.getTFieldTitle('budget_criteria'),
                   sortable: true,
               },
               {
                   key: "impact",
                   label: this.getTFieldTitle('influence'),
                   sortable: true,
               },
               {
                   key: "needs",
                   label: this.getTFieldTitle('project_need'),
                   sortable: true,
               },
               {
                   key: "total",
                   label: this.getTFieldTitle('total'),
                   sortable: true,
               },
               {
                   key: "status",
                   label: this.getFilterTitle('status'),
               },
               {
                   key: "more",
                   label: "",
               },
           ]
        }
    },

    methods: {
        async changeRegion() {
            const katos = new Set();

            for (const row of this.showBipList) {
                if (row.hasOwnProperty("localityObject") && row.localityObject) {
                    katos.add(row.localityObject.code);
                }
            }

            if (this.selRegion) {
                this.selRegion.localityList = this.selRegion.localityList.filter((row) => katos.has(row.code));
                this.selKato = null;
            }
        },

        defineBipList(serverList) {
            try {
                for (const bip of serverList) {
                    const bipObject = BipProjectDataClass.fromJSON(bip);
                    defineBip(this, bipObject, 'grade');
                    this.bipList.push(bipObject);
                    this.bipProjectData = bipObject;
                    this.pBar = (bipObject.id % 2 === 0 ? this.pBar + 20 : this.pBar - 20);
                    this.loading = true;
                }
            } catch (error) {
                console.log('defineBipList error => ' + error);
            } finally {
                console.log(new Date().toISOString(), 'bipGrade', this.bipList.length);
                this.loading = false;
                this.pBar = 100;
            }
        },

        findItem(code, field, list) {
            return findItem(code, field, list);
        },

        async loadBipPackage() {
            const params = JSON.stringify({
                variant: this.selVersion.variant_uuid,
                mode_code: this.mode_code,
                mode: 'grade'
            });
            let serverList = [];
            this.pBar = 10;
            try {
                const response = await fetch(`/api-py/bip-package/${encodeURI(params)}`)
                    .then((response) => response.json());
                this.ebkMap = new Map(Object.entries(response.abpMap));

                this.spfList = response.spfList.sort(sortByField('spf')).filter((row) => row.type === 4);
                nameLocale(this.spfList, 'spf');

                this.agrMap = await new Map(Object.entries(response.agrMap));
                serverList = await response.bipList;

            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'error_request') + ' /bip-package', error.toString());
            } finally {
                console.log(new Date().toISOString(), 'grade serverList = ', serverList.length);
                await loadEbkMap(this, 'grade');

                if (serverList.length > 0 && this.ebkMap.size > 0) {
                    setTimeout(() => {
                        this.defineBipList(serverList);
                    }, 3000);
                    this.pBar = 45;
                } else {
                    makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'no_datas_by_version'))
                    this.pBar = 100;
                    this.loading = false;
                }
            }
        },

        openAll() {
            this.open = !this.open;
            for (const row of this.showBipList) {
                row._showDetails = !this.open;
                this.showDetails(row);
            }
        },

        openBip(item) {
            this.bipProjectData = item;
            setTimeout(() => {
                this.showForm = true;
            }, 500);
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        showDetails(row) {
            row._showDetails = !row._showDetails;
            if (row._showDetails) {
                row._rowVariant = "info";
            } else {
                row._rowVariant = "";
            }
        },

        submitReport() {
            if (this.selVersion !== null) {
                let more_7 = 0;
                let between_4and7 = 0;
                let less_4 = 0;
                const datas = [];
                for (const bip of this.showBipList) {
                    bip.total = bip.total ? bip.total : 0;
                    const finList = this.setFinList(bip.addDatas['finace']);
                    const item = {
                        code: bip.code,
                        reg_loc: bip.addDatas.reg_loc,
                        abp: bip.addDatas.abp.abp,
                        exp_type: bip.addDatas.exp_type,
                        program: bip.addDatas.program,
                        type_obj: bip.addDatas.type_obj,
                        name: bip.name,
                        budget: bip.budget,
                        impact: bip.impact,
                        needs: bip.needs,
                        total: bip.total,
                        totalCost: bip.totalCost,
                        statusItem: (bip.addDatas.status ? bip.addDatas.status.name : ''),
                        dataList: finList
                    };
                    datas.push(item);

                    if (parseFloat(bip.total) < 4) {
                        less_4++;
                    }
                    if (parseFloat(bip.total) >= 4 && parseFloat(bip.total) < 7) {
                        between_4and7++;
                    }
                    if (parseFloat(bip.total) >= 7) {
                        more_7++;
                    }
                }

                // объект для передачи в отчет
                const params = {
                    lang: `${i18nService.locale}`,
                    year: this.planPeriod.year,
                    planPeriod: this.planPeriod,
                    selRegionBudget: this.selRegionBudget,
                    selDataType: this.selDataType,
                    selVersion: this.selVersion,
                    selRegion: this.selRegion,
                    selProject: this.selDirection,
                    selObject: this.selObject,
                    selStatus: this.selStatus,
                    selAbp: this.selAbp,
                    selGp: this.selGp,
                    radio: this.radio,
                    more_7: more_7,
                    between_4and7: between_4and7,
                    less_4: less_4,
                    datas: datas,
                };

                this.$emit("submitReport", params);
            }
        }, // отчет

        setFinList(finace) {
            const datas = [];
            for (const fin of finace) {
                let curFin = undefined;
                if (this.selPrg) {
                    if (fin.prg === this.selPrg.prg) {
                        if (this.selPpr) {
                            if (fin.ppr === this.selPpr.ppr) {
                                curFin = fin;
                            }
                        } else {
                            curFin = fin;
                        }
                    }
                } else {
                    curFin = fin;
                }
                if (curFin) {
                    const item = {};
                    this.$set(item, 'prg', padLeadingZeros(curFin.prg, 3));
                    this.$set(item, 'ppr', padLeadingZeros(curFin.ppr, 3));
                    this.$set(item, 'spf', padLeadingZeros(curFin.spf, 3));
                    ['pprCost', 'fact1', 'fact2', 'fact3', 'plan1', 'plan2', 'plan3']
                        .forEach(field => this.$set(item, field, curFin[field]));
                    datas.push(item);
                }
            }
            return datas;
        },

        getBipTitle(field) {
            return getBipTitle(this, field);
        },

        getCardTitle(sector, field) {
            return getCardTitle(this, sector, field);
        },

        getFilterTitle(field) {
            return getFilterTitle(this, field);
        },

        getTFieldTitle(field) {
            return getTFieldTitle(this, field);
        },

        getSmsTitle(field) {
            return getSmsTitle(this, field);
        }
    }
};
</script>
